import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { TermsAndConditionsTemplate } from '../../../shared'

const TermsAndConditionsPage = ({ data }) => (
  <Layout>
    <TermsAndConditionsTemplate data={data} />
  </Layout>
)

export default TermsAndConditionsPage

export const termsAndConditionsPageQuery = graphql`
  query TermsAndConditionsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`
